import request from "@/common/request"
import { Page, Pageable, PontoDeVenda } from "@/models"

const BASE_URL = '/pdvs'

export default class RestPdvService {
	buscar(filtro?: FiltroDeBuscaDePdv & Pageable) {
		return request.get<never, Page<PontoDeVenda>>(BASE_URL, {
			params: filtro,
		})
	}
}

export interface FiltroDeBuscaDePdv {
	lojas?: string[]
}
