










import { FindCategoriaUseCase } from '@/usecases'
import { Vue, Component, Prop } from 'vue-property-decorator'
@Component
export default class SeletorDeCategoria extends Vue {
	@Prop({ type: String }) lojaId!: string

	categoria: string[] = []
	findCategoria = new FindCategoriaUseCase

	async created() {
		this.categoria = (await this.findCategoria.findAllList())
	}
}
