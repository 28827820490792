



















































import { Vue, Component, Ref, Watch, Prop } from 'vue-property-decorator'
import { PontoDeVenda } from '@/models'
import RestPdvService from '@/services/pdv/RestPdvService'
import { nextTick } from '@/shareds/utils'

@Component
export default class FiltroAvancadoDeDashboard extends Vue {
	@Ref() form!: HTMLFormElement
	@Prop({ type: Array, default: undefined }) lojas?: string[]
	@Prop() filtroInicial?: FiltrosAvancadosDoDashboard | null
	filtro: FiltrosAvancadosDoDashboard = inicializaForm()
	pdvs: PontoDeVenda[] = []
	mostra = false

	pdvService = new RestPdvService()

	buscandoPdvs = false

	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if (!mostra) return
		this.filtro = this.filtroInicial
			? {...this.filtroInicial}
			: inicializaForm()
		this.buscarPdvs()
		setTimeout(() => {
			this.form.resetValidation()
		})
	}

	async buscarPdvs() {
		try {
			this.pdvs = []
			this.buscandoPdvs = true
			const page = await this.pdvService.buscar({
				lojas: this.lojas,
				sort: ['nome'],
			})
			this.pdvs = page.content
		} finally {
			this.buscandoPdvs = false
		}
	}

	async confirmar() {
		await nextTick()
		if (!this.form.validate() || !this.filtro) return
		this.$emit('confirmar', this.filtro)
		this.mostra = false
	}
}

export interface FiltrosAvancadosDoDashboard {
	pdvs: string[]
}

function inicializaForm(): FiltrosAvancadosDoDashboard {
	return {
		pdvs: [],
	}
}
