










import { FindTagUseCase } from '@/usecases'
import { Vue, Component, Prop } from 'vue-property-decorator'
@Component
export default class SeletorDeColecao extends Vue {
	@Prop({ type: String }) lojaId!: string

	colecao: string[] = []
	findTag = new FindTagUseCase

	async created() {
		this.colecao = (await this.findTag.findTagColecao())
	}
}
