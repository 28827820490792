






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Ref, Vue } from 'vue-property-decorator'
import { primaryColor } from '@/plugins/vuetify'
import SeletorDeLojas from '@/components/loja/SeletorDeLojas.vue'
import SeletorDeColecao from '@/components/produto/SeletorDeColecao.vue'
import SeletorDeCategoria from '@/components/produto/SeletorDeCategoria.vue'
import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue'
import { DadosDeVendaProcessada, 
	DadosDeVendasPorLoja, 
	DadosDeVendasPorVendedor, 
	DadosDemonstracaoLoja, 
	DadosPagamentoPorLoja, 
	DadosVendasExternasEInternas, 
	DadosVendasExternasEInternasPercent, 
	ItemDoRankingDeProdutos,
	DadosDeVendasPorMarca,
	DadosDeEstoque,
	Loja,
	DadosDeCustoDeItensVendidosPorCategoria,
	DadosDeVendasPorVendedorSemCrediario,
} from '@/models'
import {
	FindDadosDeVendasUsecase,
	FindDashboardsUseCase,
	FindRankingDeProdutosUseCase,
	ParametrosDoRankingDeProdutos,
	ParametrosFindDadosDeVenda,
} from '@/usecases'
import Axios from 'axios'
import axios, { CancelTokenSource } from 'axios'
import { fixarCasasDecimais, formatarMoeda } from '@/shareds/formatadores'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { DataOptions } from 'vuetify'
import { ApexOptions } from 'apexcharts'
import { backendDomain, cores } from '@/shareds/utils'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import moment from 'moment'
import { serializarQueryString } from '@/common/request'
import DatePicker from '@/components/ui/DatePicker.vue'
import FiltroAvancadoDeDashboard, { FiltrosAvancadosDoDashboard } from '@/views/application/dashboards/FiltroAvancadoDeDashboard.vue'
import { FindLojaUseCase } from '@/usecases'
import SeletorDePeriodo from './SeletorDePeriodo.vue'
import { ViewPerfilDashboard,
	DashGeral,
	TicketMedio,
	DashCashBackTotal,
	PercVendasComClientes,
	FaturamentoPorLoja,
	FaturamentoPorVendedor,
	FaturamentoPorCliente,
	DashCashBackGerado,
	DashCashBackResgatado,
} from '@/models/views/CapaDashboard'
import { convertePeriodoParaDatas, dateTimeToPtBrFormat, Periodo } from '@/shareds/date/date-utils'
import RangeDatePicker from '@/components/ui/RangeDatePicker.vue'

@Component({
	components: {
		SeletorDeLojas,
		DataTableDeCrudPaginado,
		SeletorDeColecao,
		SeletorDeCategoria,
		DatePicker,
		FiltroAvancadoDeDashboard,
		SeletorDePeriodo,
		RangeDatePicker,
	},
})
export default class TelaInicialDashBoard extends Vue {
	@Ref() grafico!: ApexOptions
	@Ref() formDePeriodo!: HTMLFormElement
	@Ref() form!: HTMLFormElement
	@Ref() dialogoDeFiltros!: FiltroAvancadoDeDashboard

	mostraEdicao = false
	datasEmEdicao = convertePeriodoParaDatas(this.periodo || 'Hoje')
	filtroDeLojas = []
	lojasUsuario: Loja[] = []

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}
	totalDeRegistros = 0

	findRankingDeProdutosUseCase = new FindRankingDeProdutosUseCase()
	findDadosDeVendasUseCase = new FindDadosDeVendasUsecase()
	findDashBoardUseCase = new FindDashboardsUseCase()
	cancelToken: CancelTokenSource | null = null
	dashGeral: DashGeral | null = null
	ticketMedio: TicketMedio[] = []
	dashCashBackTot: DashCashBackTotal | null = null
	percVendasComESemCliente: PercVendasComClientes | null = null
	faturamentoPorLoja: FaturamentoPorLoja[] = []
	faturamentoPorVendedor: FaturamentoPorVendedor[] = []
	faturamentoPorCliente: FaturamentoPorCliente[] = []
	dashCashBackGerado: DashCashBackGerado | null = null
	dashCashBackResgatado: DashCashBackResgatado | null = null
	permissoesDash: ViewPerfilDashboard[] = []
	
	chartOptions: ApexOptions = {
		theme: {
			mode: this.$vuetify.theme.dark ? 'dark' : 'light',
		},
		chart: {
			id: 'grafico-faturamento',
			type: 'area',
			stacked: true,
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: 'smooth',
		},
		markers: {
			size: 5,
			hover: {
				size: 9,
			},
		},
		fill: {
			type: 'gradient',
			gradient: {
				opacityFrom: 0.3,
				opacityTo: 0.6,
			},
		},
		legend: {
			position: 'top',
			horizontalAlign: 'center',
		},
		yaxis: {
			min: 0,
		},
		xaxis: {
			type: 'datetime',
		},
		tooltip: {
			shared: true,
			intersect: false,
			y: {
				formatter: function (y) {
					if(typeof y !== "undefined") {
						return  `R$ ${y.toFixed(2)}`;
					}
					return y;
				},
			},
		},
		colors: [primaryColor, '#eb3498'],
		title: {
			text: `R$ ${this.totalDeVendas()}`,
			offsetX: 20,
			style: {
				fontSize: '24px',
			},
		},
		subtitle: {
			text: 'Vendas',
			offsetX: 20,
			style: {
				fontSize: '14px',
			},
		},
		grid: {
			show: true,
			position: 'back',
		},
	}

	chartTicket: ApexOptions = {
		chart: {
			type: 'bar',
		},
		yaxis: {
			min: 0,
			title: {
				text: 'Valor',
			},
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '20%',
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			show: true,
			width: 10,
			colors: ['transparent'],
		},
		tooltip: {
			enabled: true,
			y: {
				formatter: function (val) {
					return 'R$' + val ;
				},
			},
		},
	}

	produtosDoRanking: ItemDoRankingDeProdutos[] = []

	headers = [
		{ text: 'Ranking', value: 'posicaoNoRanking' },
		{ text: 'Produto', value: 'nomeDoProduto', align:'right' },
		{ text: 'Quantidade', value: 'qtdeFaturada', align:'right' },
		{ text: 'Valor Faturado R$', value: 'valorFaturado', align:'right' },
	]

	headersFaturamentoPorLoja = [
		{ text: 'Identificador', value: 'identificador' },
		{ text: 'Nome Fantasia', value: 'nomeFantasia', align:'right' },
		{ text: 'Quant', value: 'quant', align:'right' },
		{ text: 'Faturamento R$', value: 'faturamento', align:'right'},
	];


	headersFaturamentoPorVendedor = [
		{ text: 'Nome Fantasia', value: 'nomeLoja' },
		{ text: 'Nome', value: 'nome', align:'right' },
		{ text: 'Quant', value: 'quant', align:'right' },
		{ text: 'Faturamento R$', value: 'faturamento', align:'right' },
	]

	headersFaturamentoPorCliente = [
		{ text: 'Nome Fantasia', value: 'nomeFantasia',  sortable: false },
		{ text: 'Cliente', value: 'nomeCliente', sortable: false, align:'right'  },
		{ text: 'Quant', value: 'quantidade', sortable: false, align:'right' },
		{ text: 'Faturamento R$', value: 'faturamento', sortable: false, align:'right'  },
	]

	headersVendasPorVendedor = [
		{ text: 'Vendedor', value: 'vendedor', sortable: false },
		{ text: 'Vlr Liq', value: 'faturamento', sortable: false, align:'right', sort: (a, b) => b.faturamento - a.faturamento },
		{ text: 'PÇ', value: 'qtdeDePecas', sortable: false, align:'right' },
		{ text: 'Dev', value: 'devolucoes', sortable: false, align:'right' },
		{ text: 'Demo', value: 'demonstracao', sortable: false, align:'right' },
		{ text: 'Omni', value: 'ecommerce', sortable: false, align:'right' },
		{ text: 'Presencial', value: 'interna', sortable: false, align:'right' },
		{ text: 'Link', value: 'link',  sortable: false, align:'right' },
		{ text: 'Outros', value: 'outro',  sortable: false, align:'right' },
		{ text: 'Ticket', value: 'somaDeVendas', sortable: false, align:'right' },
		{ text: 'TM', value: 'ticketMedio', sortable: false, align:'right' },
		{ text: 'Pm', value: 'precoMedio', sortable: false, align:'right' },
		{ text: 'Pa', value: 'pa', sortable: false, align:'right' },
	]

	headersVendasPorMarca = [
		{ text: 'Marca', value: 'marca', sortable: false },
		{ text: 'Vlr Liq', value: 'faturamento', sortable: false, align:'right' },
		{ text: 'PÇ', value: 'qtdeDePecas', sortable: false, align:'right' },
		{ text: 'Dev', value: 'devolucoes', sortable: false, align:'right' },
		{ text: 'Demo', value: 'demonstracao', sortabke: false, align:'right'},
		{ text: 'Omni', value: 'ecommerce', sortabke: false, align:'right'},
		{ text: 'Presencial', value: 'interna', sortabke: false, align:'right'},
		{ text: 'Link', value: 'link',  sortabke: false, align:'right'},
		{ text: 'Outros', value: 'outro',  sortabke: false, align:'right'},
		{ text: 'Ticket', value: 'somaDeVendas', sortable: false, align:'right' },
	]

	headersVendasPorLoja = [
		{ text: 'Loja', value: 'loja', sortable: false },
		{ text: 'Vlr Liq', value: 'faturamento', sortable: false, align:'right' },
		{ text: 'PÇ', value: 'qtdeDePecas', sortable: false, align:'right' },
		{ text: 'Ticket', value: 'somaDeVendas', sortable: false, align:'right' },
		{ text: 'TM', value: 'ticketMedio', sortable: false, align:'right' },
		{ text: 'Pm', value: 'precoMedio', sortable: false, align:'right' },
		{ text: 'Pa', value: 'pa', sortable: false, align:'right' },
	]

	headersLojaDemonstracao = [
		{ text: 'Vendedor', value: 'vendedor', sortable: false},
		{ text: 'Cliente', value: 'cliente', sortable: false, align:'right'},
		{ text: 'Total Em Aberto', value: 'total', sortable: false, align:'right'},
		{ text: 'Preço Médio', value: 'precoMedio', sortable: false, align:'right'},
		{ text: 'QTD Peças', value: 'quantidade', sortable: false, align:'right'},
		{ text: 'Dias em Aberto', value: 'diasEmAberto', sortable: false, align:'right'},
		{ text: 'Nota Fiscal', value: 'notaFiscal', sortable: false, align:'right'},
		{ text: 'Identificador', value: 'identificador', sortable: false, align:'right'},
	]

	headersLojaPagamentos = [
		{ text: 'Pagamento', value: 'pagamento', sortable: false },
		{ text: 'Total', value: 'total', sortable: false, align:'right' },
	]

	headersVendasExternasEInterna = [
		{ text: 'Origem', value: 'tipo', sortable: false },
		{ text: 'Vlr Liq', value: 'faturamento', sortable: false, align:'right' },
		{ text: '%', value: 'perc',  sortable: false, align:'right'},
		{ text: 'PÇ', value: 'pecas', sortable: false, align:'right' },
		{ text: 'Ticket', value: 'numeroDeVendas', sortable: false, align:'right' },
		{ text: 'TM', value: 'tm', sortable: false, align:'right' },
		{ text: 'Pm', value: 'pm', sortable: false, align:'right' },
		{ text: 'Pa', value: 'pa',  sortable: false, align:'right' },
	]

	headersEstoque = [
		{ text: 'Colecao', value: 'colecao', sortable: false },
		{ text: 'Categoria', value: 'categoria', sortable: false, align:'right' },
		{ text: 'Qtde Estoque', value: 'qtdeEstoque',  sortable: false, align:'right' },
		{ text: 'Qtde Empenhada', value: 'qtdeEmpenhada', sortable: false, align:'right' },
		{ text: 'Disponivel', value: 'disponivel', sortable: false, align:'right' },
		{ text: 'Preço de Venda', value: 'precoDeVenda', sortable: false, align:'right' },
		{ text: 'Preço de custo', value: 'precoDeCusto', sortable: false, align:'right' },
	]

	headersCustoDeItensVendidosPorCategoria = [
		{ text: 'Categoria', value: 'nomeDaCategoria', sortable: false },
		{ text: 'Quantidade', value: 'quantidade', sortable: false, align: 'right' },
		{ text: 'PM Tabela', value: 'precoMedioTabela', sortable: false, align: 'right' },
		{ text: 'Total Preço Tabela', value: 'precoTabelaTotal', sortable: false, align: 'right' },
		{ text: 'Valor Líquido Venda', value: 'precoVendaLiquido', sortabke: false, align: 'right' },
		{ text: 'Custo Unitário Médio', value: 'custoUnitarioMedio', sortabke: false, align: 'right' },
		{ text: 'Custo Total', value: 'custoTotal', sortabke: false, align: 'right' },
	]

	headersVendasPorVendedorSemCrediario = [
		{ text: 'Vendedor', value: 'vendedor', sortable: false },
		{ text: 'Vlr Liq', value: 'faturamento', sortable: false, align:'right', sort: (a, b) => b.faturamento - a.faturamento },
		{ text: 'Pago S/ Crediario', value: 'pagoSemCrediario', sortable: false, align: 'right' },
		{ text: 'PÇ', value: 'qtdeDePecas', sortable: false, align:'right' },
		{ text: 'Dev', value: 'devolucoes', sortable: false, align:'right' },
		{ text: 'Demo', value: 'demonstracao', sortable: false, align:'right' },
		{ text: 'Omni', value: 'ecommerce', sortable: false, align:'right' },
		{ text: 'Presencial', value: 'interna', sortable: false, align:'right' },
		{ text: 'Link', value: 'link',  sortable: false, align:'right' },
		{ text: 'Outros', value: 'outro',  sortable: false, align:'right' },
		{ text: 'Ticket', value: 'somaDeVendas', sortable: false, align:'right' },
		{ text: 'TM', value: 'ticketMedio', sortable: false, align:'right' },
		{ text: 'Pm', value: 'precoMedio', sortable: false, align:'right' },
		{ text: 'Pa', value: 'pa', sortable: false, align:'right' },
	]

	carregando = false
	dadosDeVenda: DadosDeVendaProcessada[] = []
	dadosDeVendasPorVendedor: DadosDeVendasPorVendedor[] = []
	dadosDeVendasPorMarca: DadosDeVendasPorMarca[] = []
	dadosDeVendasPorVendedorOrcamento: DadosDeVendasPorVendedor[] = []
	dadosDeVendaPorLoja: DadosDeVendasPorLoja[] = []
	dadosDeVendaOrcamentoPorLoja: DadosDeVendasPorLoja[] = []
	dadosDeDemonstracaoPorLoja: DadosDemonstracaoLoja[] = []
	dadosPagamentosPorLoja: DadosPagamentoPorLoja[] = []
	dadosPagamentosPorLojaOrcamento: DadosPagamentoPorLoja[] = []
	dadosDeEstoque: DadosDeEstoque[] = []
	dadosVendasExternasEInternas: DadosVendasExternasEInternas[] = []
	dadosVendasExternasEInternasPerc: DadosVendasExternasEInternasPercent[] = []
	dadosVendasExternasEInternasOrcamento: DadosVendasExternasEInternas[] = []
	dadosVendasExternasEInternasPercOrcamento: DadosVendasExternasEInternasPercent[] = []
	dadosDeCustoDeItensVendidosPorCategoria: DadosDeCustoDeItensVendidosPorCategoria[] = []
	colecao = null
	categoria = null
	findLojaUseCase = new FindLojaUseCase()	
	dadosDeVendasPorVendedorSemCrediario: DadosDeVendasPorVendedorSemCrediario[] = []

	botaoDeRolagemVisivel = false;
	iconeCorrespondente = 'mdi-filter-outline';
	posicaoDeRolagemAnterior = 0;
	mostra = false

	remove(item) {
		const index = this.filtroDeLojas.findIndex(loja => loja === item.id);
		if (index !== -1) {
			this.filtroDeLojas.splice(index, 1);
		}
	}


	formataNomeDaLojaChip(nome: string) {
		const nomeSplitado = nome.match(/[a-zA-Z]{4,}/g)
		if (!nomeSplitado) return nome
		return nomeSplitado.map(nome => nome.substr(0, 4)).join('.')
	}

	filtrosAvancados(filtro: FiltrosAvancadosDoDashboard){
		this.pdvs = filtro.pdvs.length	
			? filtro.pdvs
			: null
	}

	aplicarFiltro() {
		if(!this.verificaDataFinal) return
		const dataInicial = this.datasEmEdicao[0]
		const dataFinal = this.datasEmEdicao[1]
		this.periodoFiltro = [dataInicial, dataFinal]
		this.lojasFiltro = Object.values(this.filtroDeLojas).flat();
		if(this.lojas?.length === 36){
			const resultado: string = this.lojasFiltro.join('');
			this.lojasFiltro = [resultado]
		}
		this.buscaDados()
	}

	get lojasFiltro() {
		if (typeof this.$route.query.l === 'string') return [this.$route.query.l]
		return (this.$route.query.l as string[]) || null
	}

	set lojasFiltro(lojas: string[] | null) {
		this.$router
			.replace({
				name: this.$route.name as string,
				query: {
					...this.$route.query,
					l: lojas,
				},
			})
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			.catch(() => {})
	}

	get pdvsFiltro() {
		return (this.$route.query.p as string[]) || null
	}

	set Filtro(pdvs: string[] | null) {
		this.$router
			.replace({
				name: this.$route.name as string,
				query: {
					...this.$route.query,
					p: pdvs,
				},
			})
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			.catch(() => {})
	}

	get exibirGraficoMetasPorLoja() {
		return this.lojas && this.lojas.length > 0
	}

	confirmarPeriodo() {
		if (!this.formDePeriodo.validate()) return
		this.$emit('input', this.datasEmEdicao)
	}

	get periodoFiltro() {
		const periodoDaQuery = this.$route.query.d
		if (typeof periodoDaQuery === 'string' && moment(periodoDaQuery).isValid()) {
			return [periodoDaQuery, null]
		}
		if (Array.isArray(periodoDaQuery) && periodoDaQuery.length === 1 ) {
			return [periodoDaQuery[0] as string, null]
		}
		return (periodoDaQuery as Periodo) || null
	}

	set periodoFiltro(periodo: Periodo | null) {
		this.$router
			.replace({
				name: this.$route.name as string,
				query: {
					...this.$route.query,
					d: Array.isArray(periodo)
						? periodo.filter(periodo => !!periodo)
						: periodo,
				},
			})
	}



	dataHoraAtualizacao(listaDeDados: any[]) {
		if (listaDeDados && listaDeDados.length > 0 && listaDeDados[0].dataHoraMaterializada !== null) {
			return dateTimeToPtBrFormat(listaDeDados[0].dataHoraMaterializada)
		}

		return ''
	}

	dadosTemDataMaterializada(listaDeDados: any[]) {
		return listaDeDados && listaDeDados.length > 0 && listaDeDados[0].dataHoraMaterializada !== null
	}

	calcularTotal(coluna) {
		return this.dadosDeVendasPorVendedor.reduce((total, venda) => {
			const tot = total + venda[coluna];
			return fixarCasasDecimais(tot, 2)
		},0);
	}

	get dataInicial() {
		return this.datasEmEdicao[0]
	}

	get dataFinal() {
		return this.datasEmEdicao[1]
	}


	data() {
		return {
			showTooltip: false,
		}
	}

	mounted() {
		window.addEventListener('scroll', this.handleScroll);
	}

	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll);
		this.pdvs = null
	}

	handleScroll() {
		this.botaoDeRolagemVisivel = window.scrollY > 100;
	}

	acessarFiltrosOuRetornar(){
		if (window.scrollY === 0) {
			window.scrollTo({ top: this.posicaoDeRolagemAnterior + 100, behavior: 'smooth' });
			this.iconeCorrespondente = 'mdi-filter-outline';
		} else {
			this.posicaoDeRolagemAnterior = window.scrollY;
			window.scrollTo({ top: this.posicaoDeRolagemAnterior - this.posicaoDeRolagemAnterior, behavior: 'smooth' });
			this.iconeCorrespondente = 'mdi-chevron-down';
		}
	}

	calcularTotalMarca(coluna) {
		return this.dadosDeVendasPorMarca.reduce((total, venda) => {
			const tot = total + venda[coluna];
			return fixarCasasDecimais(tot, 2)
		},0);
	}

	calcularTotalOrcamento(coluna) {
		return this.dadosDeVendasPorVendedorOrcamento.reduce((total, venda) => {
			const tot = total + venda[coluna];
			return fixarCasasDecimais(tot, 2)
		},0);
	}

	calcularTotalVendasExternas(coluna) {
		return this.dadosVendasExternasEInternas.reduce((total, venda) => {
			const tot = total + venda[coluna];
			return fixarCasasDecimais(tot, 2)
		},0);
	} 


	calcularPrecoMedioTotal() {
		const faturamentoTotal = this.calcularTotal('faturamento');
		const qtdePecasTotal = this.calcularTotal('qtdeDePecas');
		const precoMedioTotal = faturamentoTotal / qtdePecasTotal;
		return precoMedioTotal;
	}
	calcularPrecoMedioTotalOrcamento() {
		const faturamentoTotal = this.calcularTotalOrcamento('faturamento');
		const qtdePecasTotal = this.calcularTotalOrcamento('qtdeDePecas');
		const precoMedioTotal = faturamentoTotal / qtdePecasTotal;
		return precoMedioTotal;
	}

	calcularTicketMedioTotal() {
		const faturamentoTotal = this.calcularTotal('faturamento');
		const totalVendasTotal = this.calcularTotal('somaDeVendas');
		const ticketMedioTotal = faturamentoTotal / totalVendasTotal;
		return ticketMedioTotal;
	}
	calcularTotalVendasExternasOrcamento(coluna) {
		return this.dadosVendasExternasEInternasOrcamento.reduce((total, venda) => {
			const tot = total + venda[coluna];
			return fixarCasasDecimais(tot, 2)
		},0);
	} 
	calcularTicketMedioTotalOrcamento() {
		const faturamentoTotal = this.calcularTotalOrcamento('faturamento');
		const totalVendasTotal = this.calcularTotalOrcamento('somaDeVendas');
		const ticketMedioTotal = faturamentoTotal / totalVendasTotal;
		return ticketMedioTotal;
	}
	calcularPaTotal() {
		const qtdePecasTotal = this.calcularTotal('qtdeDePecas');
		const totalVendasTotal = this.calcularTotal('somaDeVendas');
		const paTotal = qtdePecasTotal / totalVendasTotal;
		return fixarCasasDecimais(paTotal, 2);
	}
	calcularPaTotalOrcamento() {
		const qtdePecasTotal = this.calcularTotalOrcamento('qtdeDePecas');
		const totalVendasTotal = this.calcularTotalOrcamento('somaDeVendas');
		const paTotal = qtdePecasTotal / totalVendasTotal;
		return fixarCasasDecimais(paTotal, 2);
	}
	calcularTotalLoja(coluna) {
		return this.dadosDeVendaPorLoja.reduce((total, venda) => {
			return total + venda[coluna];
		},0);
	}
	calcularTotalLojaOrcamento(coluna) {
		return this.dadosDeVendaOrcamentoPorLoja.reduce((total, venda) => {
			return total + venda[coluna];
		},0);
	}
	calcularTicketMedioTotalLoja() {
		const faturamentoTotal = this.calcularTotalLoja('faturamento');
		const totalVendasTotal = this.calcularTotalLoja('somaDeVendas');
		if (totalVendasTotal === 0) {
			return 0;
		}
		const ticketMedioTotal = faturamentoTotal / totalVendasTotal;
		return ticketMedioTotal;
	}
	calcularPrecoMedioTotalLoja() {
		const faturamentoTotal = this.calcularTotalLoja('faturamento');
		const qtdePecasTotal = this.calcularTotalLoja('qtdeDePecas');
		if (qtdePecasTotal === 0) {
			return 0;
		}
		const precoMedioTotal = faturamentoTotal / qtdePecasTotal;
		return precoMedioTotal;
	}
	calcularPaTotalLoja() {
		const qtdePecasTotal = this.calcularTotalLoja('qtdeDePecas');
		const totalVendasTotal = this.calcularTotalLoja('somaDeVendas');
		if (totalVendasTotal === 0) {
			return 0;
		}
		const paTotal = qtdePecasTotal / totalVendasTotal;
		return fixarCasasDecimais(paTotal, 2);
	}
	calcularTicketMedioTotalLojaOrcamento() {
		const faturamentoTotal = this.calcularTotalLojaOrcamento('faturamento');
		const totalVendasTotal = this.calcularTotalLojaOrcamento('somaDeVendas');
		if (totalVendasTotal === 0) {
			return 0;
		}
		const ticketMedioTotal = faturamentoTotal / totalVendasTotal;
		return ticketMedioTotal;
	}
	calcularPrecoMedioTotalLojaOrcamento() {
		const faturamentoTotal = this.calcularTotalLojaOrcamento('faturamento');
		const qtdePecasTotal = this.calcularTotalLojaOrcamento('qtdeDePecas');
		if (qtdePecasTotal === 0) {
			return 0;
		}
		const precoMedioTotal = faturamentoTotal / qtdePecasTotal;
		return precoMedioTotal;
	}
	calcularPaTotalLojaOrcamento() {
		const qtdePecasTotal = this.calcularTotalLojaOrcamento('qtdeDePecas');
		const totalVendasTotal = this.calcularTotalLojaOrcamento('somaDeVendas');
		if (totalVendasTotal === 0) {
			return 0;
		}
		const paTotal = qtdePecasTotal / totalVendasTotal;
		return fixarCasasDecimais(paTotal, 2);
	}

	calcularTicketMedioVendasExternas() {
		const faturamentoTotal = this.calcularTotalVendasExternas('faturamento');
		const totalVendasTotal = this.calcularTotalVendasExternas('numeroDeVendas');
		if (totalVendasTotal === 0) {
			return 0;
		}
		const ticketMedioTotal = faturamentoTotal / totalVendasTotal;
		return ticketMedioTotal;
	}
	calcularTicketMedioVendasExternasOrcamento() {
		const faturamentoTotal = this.calcularTotalVendasExternasOrcamento('faturamento');
		const totalVendasTotal = this.calcularTotalVendasExternasOrcamento('numeroDeVendas');
		if (totalVendasTotal === 0) {
			return 0;
		}
		const ticketMedioTotal = faturamentoTotal / totalVendasTotal;
		return ticketMedioTotal;
	}

	calcularPrecoMedioVendasExternas() {
		const faturamentoTotal = this.calcularTotalVendasExternas('faturamento');
		const qtdePecasTotal = this.calcularTotalVendasExternas('pecas');
		if (qtdePecasTotal === 0) {
			return 0;
		}
		const precoMedioTotal = faturamentoTotal / qtdePecasTotal;
		return precoMedioTotal;
	}
	
	calcularPrecoMedioVendasExternasOrcamento() {
		const faturamentoTotal = this.calcularTotalVendasExternasOrcamento('faturamento');
		const qtdePecasTotal = this.calcularTotalVendasExternasOrcamento('pecas');
		if (qtdePecasTotal === 0) {
			return 0;
		}
		const precoMedioTotal = faturamentoTotal / qtdePecasTotal;
		return precoMedioTotal;
	}

	calcularPaTotalVendasExternas() {
		const qtdePecasTotal = this.calcularTotalVendasExternas('pecas');
		const totalVendasTotal = this.calcularTotalVendasExternas('numeroDeVendas');
		if (totalVendasTotal === 0) {
			return 0;
		}
		const paTotal = qtdePecasTotal / totalVendasTotal;
		return fixarCasasDecimais(paTotal, 2);
	}

	calcularPaTotalVendasExternasOrcamento() {
		const qtdePecasTotal = this.calcularTotalVendasExternasOrcamento('pecas');
		const totalVendasTotal = this.calcularTotalVendasExternasOrcamento('numeroDeVendas');
		if (totalVendasTotal === 0) {
			return 0;
		}
		const paTotal = qtdePecasTotal / totalVendasTotal;
		return fixarCasasDecimais(paTotal, 2);
	}

	calcularTotalGeral(items) {
		const tot = items.reduce((acc, item) => {
			const valorLimpo = item.total.replace(/[^0-9,]+/g, '').replace(',', '.');
			const totalNumerico = parseFloat(valorLimpo);
			return acc + (isNaN(totalNumerico) ? 0 : totalNumerico);
		}, 0);
		return tot;
	}

	formatarData(data) {
		if (!data || !moment(data).isValid()) return '';
		return moment(data).format('DD-MM-YYYY');
	}

	formatarMoeda(valor) {
		return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
	}

	get verificaDataFinal(){
		if(!this.datasEmEdicao[1]) return false
		const dataInicio = new Date(this.datasEmEdicao[0]);
		const dataFinal = new Date(this.datasEmEdicao[1]);

		if(dataFinal < dataInicio){
			AlertModule.setError('Data final não pode ser menor que Data inicio')
			return false
		}else{
			return true
		}
	}

	async created() {
		this.aplicarFiltro()
		if(this.idUsuarioLogado) 
			this.permissoesDash  = await this.findDashBoardUseCase.listarDashPorPerfil(this.idUsuarioLogado)
		else throw new Error("Usuario Inválido")
	}

	get dadosDeCustoDeItensVendidosPorCategoriaFormatado() {
		return this.dadosDeCustoDeItensVendidosPorCategoria.map(dados => ({
			nomeDaCategoria: dados.nomeDaCategoria,
			quantidade: dados.quantidade,
			precoMedioTabela: 'R$ ' + formatarMoeda(dados.precoMedioTabela),
			precoTabelaTotal: 'R$ ' + formatarMoeda(dados.precoTabelaTotal),
			precoVendaLiquido: 'R$ ' + formatarMoeda(dados.precoVendaLiquido),
			custoUnitarioMedio: 'R$ ' + formatarMoeda(dados.custoUnitarioMedio),
			custoTotal: 'R$ ' + formatarMoeda(dados.custoTotal),
		}))
	}

	get idUsuarioLogado(): string | undefined {
		return UserLoginStore.usuario?.perfil.id || undefined
	}

	get series() {
		const categorias = mapeiaTiposDeClientes(this.dadosDeVenda)

		return categorias
			.map(categoria => {
				const data = this.dadosDeVenda
					.filter(venda => venda.tipoDeCliente?.toUpperCase() === categoria?.toUpperCase())
					.map(venda => [venda.dataHoraVenda.substr(0, 10), venda.totalDaVenda])

				const dadosReduzidos = data.reduce((totais, venda) => {
					const index = totais.findIndex(total => total[0] === venda[0])
					if (index === -1) {
						totais.push(venda as any)
						return totais
					}
					totais[index][1] += venda[1]
					return totais
				}, [])
					.map(serie => [serie[0], serie[1].toFixed(2)])

				const grupo = categoria === null
					? 'Normal'
					: categoria
				return {
					name: grupo,
					data: [...dadosReduzidos],
				}
			})
	}

	get seriesTicket() {
		return [{
			name: 'Ticket Médio',
			data: this.ticketMedio.map(item => {
				return{
					x: item.nomeFantasia,
					y: item.ticketMedio,
				}
			}),
		}]
	}

	get itensDonut() {
		const categorias = mapeiaTiposDeClientes(this.dadosDeVenda)

		return categorias.map((categoria, index) => {
			const total = this.dadosDeVenda
				.reduce((total, atual) => atual.tipoDeCliente?.toUpperCase() === categoria?.toUpperCase() ? total + 1 : total, 0)
			const percentual = (total / this.dadosDeVenda.length) * 100
			return {
				totalCliente: total,
				tipoDeCliente: categoria === null ? 'Normal' : categoria,
				percentual: percentual.toFixed(2),
				cor: cores[index],
			}
		})
	}
	
	get perc() {
		if (!this.percVendasComESemCliente) {
			return {
				percComClientes: 0,
				percSemClientes: 0,
			};
		}
		return {
			percComClientes: this.percVendasComESemCliente.percComClientes,
			percSemClientes: this.percVendasComESemCliente.percSemClientes,
		};
	}


	totalDeVendas() {
		if (!this.dadosDeVenda) return 0
		const total = this.dadosDeVenda
			.reduce((total, { totalDaVenda }) => total + totalDaVenda, 0)
		return formatarMoeda(total)
	}

	get totalFaturamento() {
		if (this.dadosVendasExternasEInternas) {
			return this.dadosVendasExternasEInternas.reduce((total, item) => total + item.faturamento, 0);
		}
		return 0;
	}
	

	get totalFaturamentoOrcamento() {
		if (this.dadosVendasExternasEInternasOrcamento) {
			return this.dadosVendasExternasEInternasOrcamento.reduce((total, item) => total + item.faturamento, 0);
		}
		return 0;
	}

	calcularPercentTotal(faturamento) {
		if (this.totalFaturamento > 0) {
			return ((faturamento / this.totalFaturamento) * 100).toFixed(2) + '%';
		}
		return '0.00%';
	}

	calcularPercentTotalOrcamento(faturamento) {
		if (this.totalFaturamentoOrcamento > 0) {
			return ((faturamento / this.totalFaturamentoOrcamento) * 100).toFixed(2) + '%';
		}
		return '0.00%';
	}

	get tamanhoDoCardDeDonut() {
		return this.itensDonut.length >= 3
			? 4
			: 12/this.itensDonut.length

	}	
	
	async buscaDados(
		parametros?: ParametrosDoRankingDeProdutos & ParametrosFindDadosDeVenda,
	) {
		try {		
			if (this.cancelToken) this.cancelToken.cancel()
			this.cancelToken = Axios.CancelToken.source()
			this.carregando = true

			const filtros = this.filtros
			let adjustedDataInicial = '';
			if (this.dataInicial) {
				const dataInicial = new Date(this.dataInicial);
				dataInicial.setUTCHours(3, 0, 0, 0);
				adjustedDataInicial = dataInicial.toISOString();
			}

			let adjustedDataFinal = '';
			if (this.dataFinal) {
				const dataFinal = new Date(this.dataFinal);
				dataFinal.setUTCHours(3, 0, 0, 0);
				adjustedDataFinal = dataFinal.toISOString();
			}

			const params = {
				...parametros,
				...filtros,
				page: {
					page: this.paginacao.page - 1,
					size: this.paginacao.itemsPerPage,
				},
				colecao: this.colecao,
				categoria: this.categoria,
				dataInicial: adjustedDataInicial || '',
				dataFinal: adjustedDataFinal || '',
			};
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			if(this.permissoesDash.filter(permissao => permissao.nomeDaCapa == 'Vendas' ).length > 0) {
				this.dadosDeVenda = await this.findDadosDeVendasUseCase.find(params, axiosConfig)
			}
			const pagina = await this.findRankingDeProdutosUseCase.find(params, axiosConfig)
			this.totalDeRegistros = pagina.totalElements
			this.produtosDoRanking = pagina.content

			if(this.permissoesDash.filter(permissao => permissao.nomeDaCapa == 'Vendas Por Vendedor' ).length > 0 && (!this.lojas || this.lojas.length === 1)) {
				this.dadosDeVendasPorVendedor = await this.findDadosDeVendasUseCase.findDadosDeVendaPorVendedor(params, axiosConfig)
			}

			if(this.permissoesDash.filter(permissao => permissao.nomeDaCapa == 'Vendas Por Marca' ).length > 0 && (!this.lojas || this.lojas.length === 1)) {
				this.dadosDeVendasPorMarca = await this.findDadosDeVendasUseCase.findDadosDeVendaPorMarca(params, axiosConfig)
			}
			
			if(this.permissoesDash.filter(permissao => permissao.nomeDaCapa == 'Vendas Por Vendedor Orcamento' ).length > 0 && (!this.lojas || this.lojas.length === 1)) {
				this.dadosDeVendasPorVendedorOrcamento = await this.findDadosDeVendasUseCase.findDadosDeVendasPorVendedorOrcamento(params, axiosConfig)
			}

			if(this.permissoesDash.filter(permissao => permissao.nomeDaCapa == 'Vendas Por Loja' ).length > 0) {
				this.dadosDeVendaPorLoja = await this.findDadosDeVendasUseCase.findDadosDeVendaPorLoja(params, axiosConfig)
			}

			if(this.permissoesDash.filter(permissao => permissao.nomeDaCapa == 'Vendas Orcamento Por Loja' ).length > 0) {
				this.dadosDeVendaOrcamentoPorLoja = await this.findDadosDeVendasUseCase.findDadosDeVendasOrcamentoPorLoja(params, axiosConfig)
			}

			if(this.permissoesDash.filter(permissao => permissao.nomeDaCapa == 'Demonstracoes Em Aberto' ).length > 0 && (!this.lojas || this.lojas.length === 1)) {
				this.dadosDeDemonstracaoPorLoja = await this.findDadosDeVendasUseCase.findDadosDeDemonstracaoPorLoja(params, axiosConfig)
			}

			if(this.permissoesDash.filter(permissao => permissao.nomeDaCapa == 'Pagamentos Por Loja' ).length > 0 && (!this.lojas || this.lojas.length === 1)) {
				this.dadosPagamentosPorLoja = await this.findDadosDeVendasUseCase.findDadosDePagamentoPorLoja(params, axiosConfig)
			}

			if(this.permissoesDash.filter(permissao => permissao.nomeDaCapa == 'Pagamentos Por Loja Orcamento' ).length > 0 && (!this.lojas || this.lojas.length === 1)) {
				this.dadosPagamentosPorLojaOrcamento = await this.findDadosDeVendasUseCase.findDadosDePagamentoPorLojaOrcamento(params, axiosConfig)
			}

			if(this.permissoesDash.filter(permissao => permissao.nomeDaCapa == 'Estoque Por Colecao e Categoria' ).length > 0 && (!this.lojas || this.lojas.length === 1)) {
				this.dadosDeEstoque = await this.findDadosDeVendasUseCase.findDadosDeEstoque(params, axiosConfig)
			}
					
			if(this.permissoesDash.filter(permissao => permissao.nomeDaCapa == 'Custo De Itens Vendidos Por Categoria' ).length > 0 && (!this.lojas || this.lojas.length === 1)) {
				this.dadosDeCustoDeItensVendidosPorCategoria = await this.findDadosDeVendasUseCase.findDadosCustoDeItensVendidosPorCategoria(params, axiosConfig)
			}

			if (
				this.permissoesDash.filter(
					(permissao) => permissao.nomeDaCapa == 'Vendas Externas e Internas',
				).length > 0 &&
				(!this.lojas || this.lojas.length === 1)
			) {
				this.dadosVendasExternasEInternas = await this.findDadosDeVendasUseCase.findDadosVendasExternasEInternas(
					params,
					axiosConfig,
				);

				this.dadosVendasExternasEInternasPerc = [];

				for (let i = 0; i < this.dadosVendasExternasEInternas.length; i++) {
					const item = this.dadosVendasExternasEInternas[i];
					const percent = this.calcularPercentTotal(item.faturamento);


					const novoItem = {
						tipo: item.tipo,
						faturamento: item.faturamento,
						perc: percent,
						pecas: item.pecas,
						numeroDeVendas: item.numeroDeVendas,
						tm: item.tm,
						pm: item.pm,
						pa: item.pa,
						dataHoraMaterializada: item.dataHoraMaterializada,
					};

					
					this.dadosVendasExternasEInternasPerc.push(novoItem);
				}
			}

			
			if (
				this.permissoesDash.filter(
					(permissao) => permissao.nomeDaCapa == 'Vendas Externas e Internas Orcamento',
				).length > 0 &&
				(!this.lojas || this.lojas.length === 1)
			) {
				this.dadosVendasExternasEInternasOrcamento = await this.findDadosDeVendasUseCase.findDadosVendasOrcamentoExternasDemonstracao(
					params,
					axiosConfig,
				);

				this.dadosVendasExternasEInternasPercOrcamento = [];

				for (let i = 0; i < this.dadosVendasExternasEInternasOrcamento.length; i++) {
					const item = this.dadosVendasExternasEInternasOrcamento[i];
					const percent = this.calcularPercentTotalOrcamento(item.faturamento);

					const novoItem = {
						tipo: item.tipo,
						faturamento: item.faturamento,
						perc: percent,
						pecas: item.pecas,
						numeroDeVendas: item.numeroDeVendas,
						tm: item.tm,
						pm: item.pm,
						pa: item.pa,
						dataHoraMaterializada: item.dataHoraMaterializada,
					};

					
					this.dadosVendasExternasEInternasPercOrcamento.push(novoItem);
				}
			}

			if(this.permissoesDash.filter(permissao => permissao.nomeDaCapa == 'Vendas Por Vendedor Sem Crediario' ).length > 0 && (!this.lojas || this.lojas.length === 1)) {
				this.dadosDeVendasPorVendedorSemCrediario = await this.findDadosDeVendasUseCase.findVendasOrcamentosPorVendedorSemCrediario(params, axiosConfig)
			}
			
			this.dashGeral = await this.findDashBoardUseCase.findDashGeral(params, axiosConfig);

			this.ticketMedio = this.dashGeral.ticketMedio.map(dashGeral => ({
				nomeFantasia: dashGeral.nomeFantasia,
				ticketMedio: dashGeral.ticketMedio,
			}));

			this.dashCashBackTot = this.dashGeral.dashCashBackTotal;

			this.percVendasComESemCliente = this.dashGeral.percVendasComClientes;

			this.faturamentoPorLoja = this.dashGeral.faturamentoPorLojas.map(dashGeral => ({
				faturamento: dashGeral.faturamento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).slice(2),
				nomeFantasia: dashGeral.nomeFantasia,
				quant: dashGeral.quant,
				identificador: dashGeral.identificador,
			}));

			this.faturamentoPorVendedor = this.dashGeral.faturamentoPorVendedores.map(dashGeral => ({
				quant: dashGeral.quant,
				nome: dashGeral.nome,
				nomeLoja: dashGeral.nomeLoja,
				faturamento: dashGeral.faturamento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).slice(2),
			}));

			this.faturamentoPorCliente = this.dashGeral.faturamentoPorClientes.map(dashGeral => ({
				quantidade: dashGeral.quantidade,
				faturamento: dashGeral.faturamento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).slice(2),
				nomeFantasia: dashGeral.nomeFantasia,
				nomeCliente: dashGeral.nomeCliente,
			}));

			this.dashCashBackResgatado = this.dashGeral.dashCashBackResgatado;
			this.dashCashBackGerado = this.dashGeral.dashCashBackGerado;

			if (this.permissoesDash.some(permissao => permissao.nomeDaCapa === 'Vendas')) {
				this.$apexcharts.exec('grafico-faturamento', 'updateOptions', {
					title: {
						text: `R$ ${this.totalDeVendas()}`,
					},
				});
			}

		} catch (e) {
			if (axios.isCancel(e)) return
			AlertModule.setError(e)
		} finally {
			this.carregando = false
		}
	}

	get filtros () {

		if(!this.periodo) return null

		const dataInicial = this.periodo[0] ? moment(this.periodo[0]).startOf('day').toISOString(true) : moment().startOf('day').toISOString(true);
		const dataFinal = this.periodo[1] ? moment(this.periodo[1]).endOf('day').toISOString(true) : moment().endOf('day').toISOString(true);		
		const lojas = this.lojas || undefined
		const pdvs = this.pdvs || undefined
		const colecao = this.colecao || undefined
		const categoria = this.categoria || undefined
		const filtros = {
			lojas,
			pdvs,
			dataInicial,
			dataFinal,
			colecao,
			categoria,
		}		
		return filtros
	}

	get produtosDoRankingFormatados() {
		return this.produtosDoRanking
			.map(i => ({
				...i,
				valorFaturado: formatarMoeda(i.valorFaturado || 0),
			}))
	}

	isReverseData() {
		if(!this.periodo) return

		if (!this.periodo[0] || !this.periodo[1]) return false
		if (this.periodo[1] < this.periodo[0]) return true
	}	

	get urlRelatorioPorVendedor() {
		if (!UserLoginStore.token) return ''
		const queryString = serializarQueryString({
			token: encodeURI(UserLoginStore.token),
			...this.filtros,
		})
		return `${backendDomain()}/vendas/relatorio-por-vendedor/VendasPorVendedor.csv?${queryString}`
	}

	get urlRankigDeProdutos() {
		if (!UserLoginStore.token) return ''
		const queryString = serializarQueryString({
			token: encodeURI(UserLoginStore.token),
			...this.filtros,
		})
		return `${backendDomain()}/vendas/relatorio-ranking-de-produtos/RankingDeProdutos.csv?${queryString}`
	}

	get urlDaRelacaoDeVendas() {
		if (!UserLoginStore.token) return ''
		const queryString = serializarQueryString({
			token: encodeURI(UserLoginStore.token),
			...this.filtros,
		})
		return `${backendDomain()}/vendas/relacao-de-vendas/RelacaoDeVendas.csv?${queryString}`
	}

	get urlDaRelacaoVendaVendedor() {
		if (!UserLoginStore.token) return ''
		const queryString = serializarQueryString({
			token: encodeURI(UserLoginStore.token),
			...this.filtros,
		})
		return `${backendDomain()}/vendas/relatorio-vendas-por-vendedor/VendasPorVendedor.csv?${queryString}`
	}

	get urlDaRelacaoVendaPorMarca() {
		if (!UserLoginStore.token) return ''
		const queryString = serializarQueryString({
			token: encodeURI(UserLoginStore.token),
			...this.filtros,
		})
		return `${backendDomain()}/vendas/relatorio-venda-por-marca/VendasPorMarca.csv?${queryString}`
	}

	get urlDaRelacaoDosDadosDeCustoDeItensVendidosPorCategoria() {
		if (!UserLoginStore.token) return ''
		const queryString = serializarQueryString({
			token: encodeURI(UserLoginStore.token),
			...this.filtros,
		})
		return `${backendDomain()}/vendas/custo_itens_vendidos_por_categoria_franquias/CustoDeItensVendidosPorCategoria.csv?${queryString}`
	}

	get urlDaRelacaoVendaLoja() {
		if (!UserLoginStore.token) return ''
		const queryString = serializarQueryString({
			token: encodeURI(UserLoginStore.token),
			...this.filtros,
		})
		return `${backendDomain()}/vendas/relatorio-vendas-por-loja/VendasPorLoja.csv?${queryString}`
	}

	
	get urlDaRelacaoVendaOrcamentoPorLoja() {
		if (!UserLoginStore.token) return ''
		const queryString = serializarQueryString({
			token: encodeURI(UserLoginStore.token),
			...this.filtros,
		})
		return `${backendDomain()}/vendas/relatorio-vendas-por-loja-orcamento/VendasPorLoja.csv?${queryString}`
	}
	
	get urlDaRelacaoLojaDemonstracao() {
		if (!UserLoginStore.token) return ''
		const queryString = serializarQueryString({
			token: encodeURI(UserLoginStore.token),
			...this.filtros,
		})
		return `${backendDomain()}/vendas/relatorio-demonstracao-de-loja/DemonstracaoLoja.csv?${queryString}`
	}

	get urlDaRelacaoLojaPagamento() {
		if (!UserLoginStore.token) return ''
		const queryString = serializarQueryString({
			token: encodeURI(UserLoginStore.token),
			...this.filtros,
		})
		return `${backendDomain()}/vendas/relatorio-pagamento-de-loja/PagamentoDaLoja.csv?${queryString}`
	}

	get urlDaRelacaoLojaPagamentoOrcamento() {
		if (!UserLoginStore.token) return ''
		const queryString = serializarQueryString({
			token: encodeURI(UserLoginStore.token),
			...this.filtros,
		})
		return `${backendDomain()}/vendas/relatorio-pagamento-de-loja-orcamento/PagamentoDaLoja.csv?${queryString}`
	}

	
	get urlDaRelacaoEstoquePorColecao() {
		if (!UserLoginStore.token) return ''
		const queryString = serializarQueryString({
			token: encodeURI(UserLoginStore.token),
			...this.filtros,
		})
		return `${backendDomain()}/vendas/relatorio-estoque-por-colecao/EstoquePorColecao.csv?${queryString}`
	}

	get urlDaRelacaoVendaVendedorOrcamento() {
		if (!UserLoginStore.token) return ''
		const queryString = serializarQueryString({
			token: encodeURI(UserLoginStore.token),
			...this.filtros,
		})
		return `${backendDomain()}/vendas/relatorio-vendas-por-vendedor-orcamento/VendasPorVendedor.csv?${queryString}`
	}

	get periodo(): Periodo | null {
		const periodoDaQuery = this.$route.query.d
		if (typeof periodoDaQuery === 'string' && moment(periodoDaQuery).isValid()) {
			return [periodoDaQuery, null]
		}
		return (periodoDaQuery as Periodo) || null
	}

	get lojas() {
		if (typeof this.$route.query.l === 'string') return [this.$route.query.l]
		return (this.$route.query.l as string[]) || null
	}

	set lojas(lojas: string[] | null){
		if (!lojas) return;

		
		this.$router
			.replace({
				name: this.$route.name as string,
				query: {
					...this.$route.query,
					l: lojas,
				},
			})
			.catch()
	}

	get pdvs() {
		return (this.$route.query.p as string[]) || null
	}

	set pdvs(pdvs: string[] | null) {
		this.$router
			.replace({
				name: this.$route.name as string,
				query: {
					...this.$route.query,
					p: pdvs,
				},
			})
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			.catch(() => {})
	}
	

	irPara(identificador: string) {

		const filtro = {
			busca: '',
			lojaId: '',
			datas: [null, null],
			horas: [null, null],
			cliente: null,
			pdvId: '',
			numeroDaNota: '',
			serieFiscal: '',
			exibeVendasComErros: false,
			ambientes: ['Homologação', 'Produção'],
			identificador: identificador,
			identificadorExterno: '',
			vendasComOrcamento: false,
			vendasComPagamentoCancelado: false,
			origem: null,
		};

		localStorage.setItem('FILTRO_DE_VENDAS', JSON.stringify(filtro));
		window.open(`/vendas`, '_blank');
	}
	
	converteParaNumber(valor: any){
		if (typeof valor === 'string'){
			const valorConvertido = parseFloat(valor.replace(",","."));
			return isNaN(valorConvertido) ? 0 : valorConvertido;
		}
		return valor;
	}

	get dadosPagamentosPorLojaFormatados(){
		return this.dadosPagamentosPorLoja
			.map(i => ({
				...i, 
				total: 'R$' + formatarMoeda(i.total),
				
			}))
	}

	get dadosPagamentosPorLojaOrcamentoFormatados(){
		return this.dadosPagamentosPorLojaOrcamento
			.map(i => ({
				...i, 
				total: 'R$' + formatarMoeda(i.total),
			}))
	}

	get faturamentoPorLojaFormatado(){
		return this.faturamentoPorLojaFormatado
			.map(i => ({
				...i,
				faturamento: 'R$' + formatarMoeda(this.converteParaNumber(i.faturamento)),
			}))
	}

	get faturamentoPorClienteFormatado(){
		return this.faturamentoPorCliente
			.map(i => ({
				...i,
				faturamento: 'R$' + formatarMoeda(this.converteParaNumber(i.faturamento)),
			}))
	}

	get dadosDeVendaOrcamentoPorLojaFormatados(){
		return this.dadosDeVendaOrcamentoPorLoja
			.map(i => ({
				...i,
				faturamento: 'R$' + formatarMoeda(i.faturamento),
				ticketMedio: 'R$' + formatarMoeda(i.ticketMedio),
				precoMedio: 'R$' + formatarMoeda(i.precoMedio),
			}))
	}

	get dadosDeVendaPorLojaFormatados(){
		return this.dadosDeVendaPorLoja
			.map(i => ({
				...i,
				faturamento: 'R$' + formatarMoeda(i.faturamento),
				ticketMedio: 'R$' + formatarMoeda(i.ticketMedio),
				precoMedio: 'R$' + formatarMoeda(i.precoMedio),
			}))
	}

	get dadosDeVendasPorVendedorFormatados(){
		return this.dadosDeVendasPorVendedor
			.map(i => ({
				...i,
				faturamento: 'R$' + formatarMoeda(i.faturamento),
				devolucoes: 'R$' + formatarMoeda(i.devolucoes),
				demonstracao: 'R$' + formatarMoeda(i.demonstracao),
				ecommerce: 'R$' + formatarMoeda(i.ecommerce),
				interna: 'R$' + formatarMoeda(i.interna),
				link: 'R$' + formatarMoeda(i.link),
				outro: 'R$' + formatarMoeda(i.outro),
				ticketMedio: 'R$' + formatarMoeda(i.ticketMedio),
				precoMedio: 'R$' + formatarMoeda(i.precoMedio),
			}))
	}

	get dadosDeVendasPorMarcaFormatados(){
		return this.dadosDeVendasPorMarca
			.map(i => ({
				...i,
				faturamento: 'R$' + formatarMoeda(i.faturamento),
				devolucoes: 'R$' + formatarMoeda(i.devolucoes),
				demonstracao: 'R$' + formatarMoeda(i.demonstracao),
				ecommerce: 'R$' + formatarMoeda(i.ecommerce),
				interna: 'R$' + formatarMoeda(i.interna),
				link: 'R$' + formatarMoeda(i.link),
				outro: 'R$' + formatarMoeda(i.outro),
			}))
	}

	get dadosVendasExternasEInternasPercOrcamentoFormatados(){
		return this.dadosVendasExternasEInternasPercOrcamento
			.map(i => ({
				...i,
				faturamento: 'R$' + formatarMoeda(i.faturamento),
				tm: 'R$' + formatarMoeda(i.tm),
				pm: 'R$' + formatarMoeda(i.pm),
			}))
	}

	get dadosVendasExternasEInternasPercFormatados(){
		return this.dadosVendasExternasEInternasPerc
			.map(i => ({
				...i,
				faturamento: 'R$' + formatarMoeda(i.faturamento),
				tm: 'R$' + formatarMoeda(i.tm),
				pm: 'R$' + formatarMoeda(i.pm),
			}))
	}

	get dadosDeDemonstracaoPorLojaFormatados(){
		return this.dadosDeDemonstracaoPorLoja
			.map(i => ({
				...i,
				total: 'R$' + formatarMoeda(i.total),
				precoMedio: 'R$' + formatarMoeda(i.precoMedio),
			}))
	}

	get dadosDeVendasPorVendedorOrcamentoFormatados(){
		return this.dadosDeVendasPorVendedorOrcamento
			.sort((a, b) => b.faturamento - a.faturamento)
			.map(i => ({
				...i,
				faturamento: 'R$' + formatarMoeda(i.faturamento),
				devolucoes: 'R$' + formatarMoeda(i.devolucoes),
				demonstracao: 'R$' + formatarMoeda(i.demonstracao),
				ecommerce: 'R$' + formatarMoeda(i.ecommerce),
				interna: 'R$' + formatarMoeda(i.interna),
				link: 'R$' + formatarMoeda(i.link),
				outro: 'R$' + formatarMoeda(i.outro),
				ticketMedio: 'R$' + formatarMoeda(i.ticketMedio),
				precoMedio: 'R$' + formatarMoeda(i.precoMedio),
			}))
	}

	get dadosDeVendasPorVendedorFormatadosSemCrediario(){
		return this.dadosDeVendasPorVendedorSemCrediario
			.map(i => ({
				...i,
				faturamento: 'R$' + formatarMoeda(i.faturamento),
				devolucoes: 'R$' + formatarMoeda(i.devolucoes),
				demonstracao: 'R$' + formatarMoeda(i.demonstracao),
				ecommerce: 'R$' + formatarMoeda(i.ecommerce),
				interna: 'R$' + formatarMoeda(i.interna),
				link: 'R$' + formatarMoeda(i.link),
				outro: 'R$' + formatarMoeda(i.outro),
				ticketMedio: 'R$' + formatarMoeda(i.ticketMedio),
				precoMedio: 'R$' + formatarMoeda(i.precoMedio),
				pagoSemCrediario: 'R$' + formatarMoeda(i.pagoSemCrediario),
			}))
	}

	calcularTotalSemCrediario(coluna) {
		return this.dadosDeVendasPorVendedorSemCrediario.reduce((total, venda) => {
			const tot = total + venda[coluna];
			return fixarCasasDecimais(tot, 2)
		},0);
	}

	calcularTicketMedioTotalSemCrediario() {
		const faturamentoTotal = this.calcularTotalSemCrediario('faturamento');
		const totalVendasTotal = this.calcularTotalSemCrediario('somaDeVendas');
		const ticketMedioTotal = faturamentoTotal / totalVendasTotal;
		return ticketMedioTotal;
	}

	calcularPrecoMedioTotalSemCrediario() {
		const faturamentoTotal = this.calcularTotalSemCrediario('faturamento');
		const qtdePecasTotal = this.calcularTotalSemCrediario('qtdeDePecas');
		const precoMedioTotal = faturamentoTotal / qtdePecasTotal;
		return precoMedioTotal;
	}

	calcularPaTotalSemCrediario() {
		const qtdePecasTotal = this.calcularTotalSemCrediario('qtdeDePecas');
		const totalVendasTotal = this.calcularTotalSemCrediario('somaDeVendas');
		const paTotal = qtdePecasTotal / totalVendasTotal;
		return fixarCasasDecimais(paTotal, 2);
	}

}

function mapeiaTiposDeClientes(dados: DadosDeVendaProcessada[]) {
	return dados
		.map(({ tipoDeCliente }) => tipoDeCliente ? tipoDeCliente.toUpperCase() : null)
		.reduce<(string | null)[]>((acc, item) => acc.includes(item)
			? acc
			: [ ...acc, item ]
		, [])
		.sort((a, b) => {
			if (!b) return -1
			if (!a) return 1
			return b.localeCompare(a)
		})
}
